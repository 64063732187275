.ProseMirror:focus {
    outline: none;
  }
  .ProseMirror {
    margin-left: .5em;
    padding: .5em;
    height: 265px;
    overflow-y: auto;
  }
  .has-focus {
    outline: none;
  }
  .tiptap {
    > * + * {
      margin-top: 0.75em;
    }
    ul,
    ol {
      padding: 0 1rem;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      line-height: .5;
    }
    code {
      background-color: rgba(#616161, 0.1);
      color: #616161;
    }
    pre {
      background: #0D0D0D;
      color: #FFF;
      font-family: 'JetBrainsMono', monospace;
      padding: 0.75rem 1rem;
      border-radius: 0.5rem;
      code {
        color: inherit;
        padding: 0;
        background: none;
        font-size: 0.8rem;
      }
    }
    img {
      max-width: 100%;
      height: auto;
    }
    blockquote {
      padding-left: 1rem;
      border-left: 2px solid rgba(#0D0D0D, 0.1);
    }
    hr {
      border: none;
      border-top: 2px solid rgba(#0D0D0D, 0.1);
      margin: 2rem 0;
    }
  } 
  .tiptap p.is-empty:first-child::before {
    color: #adb5bd;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }